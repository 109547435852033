export default {
  report: {
    printed_day: 'PRINTED DAY',
    account: 'ACCOUNT',
    farm_report: 'FARM REPORT',
    localization: 'LOCATION',
    who_printed: 'WHO PRINTED',
    total_of_sprays: 'SPRAYS AVERAGE',
    average_harvest_per_plot: 'YIELD',
    total_value_of_defensives_used: 'AMOUNT SPENT ON FUNGICIDES',
    report: 'REPORT',
    fungicides_applied: 'SPRAYED FUNGICIDES',
    product: 'PRODUCT',
    quantity: 'DOSES',
    liters: 'GALLON',
    kilos: 'POUNDS',
    value: 'VALUE',
    variety: 'VARIETY',
    company: 'OWNER',
    amount_per_hectare: 'PLANTED ACRES',
    sprays: 'SPRAYS',
    already_applied_on_the_day: 'ALREADY SPRAYED AT THE DAY',
    next_spray: 'NEXT SPRAY',
    to_confirm: 'TO BE CONFIRMED',
    defensive: 'FUNGICIDE',
    performance: 'Performance',
    genetics: 'GENETICS',
    culture: 'CROP',
    seeding_area: 'PLANTED AREA',
    productivity: 'AVERAGE YIELD',
    plot: 'PLOT',
    area: 'AREA',
    not_sprays_avaliable: 'No spray on this planting stage',
    harvest_unavailable: 'Fill out the harvest data',
    btn_season_report: 'Print report',
    area_sowing: 'PLANTED AREA',
    confirm: 'Confirmed',
    varieties_used: 'Planted varieties',
    production: 'TOTAL YIELD',
  },
}
