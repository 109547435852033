import { fields, ndvis } from '@/modules/plot/mock/fieldsSeeds'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import moment from 'moment'

const fieldRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/talhoes/:farm_id',
      result: ({ params }) => {
        const filteredFields = fields.filter(
          (field) =>
            field.fazenda_id == params.farm_id &&
            field.enabled &&
            !field.deleted_at
        )
        return filteredFields
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/talhoes',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        responseObj.id = fields.length + 1
        responseObj.enabled = 1
        responseObj.area_calculada = null
        responseObj.clay_soil = 0
        responseObj.created_at = '2024-01-01 01:01:01'
        responseObj.deleted_at = null
        responseObj.elevation = 1
        responseObj.ndviEnabled = 0
        responseObj.soil_type_id = null
        responseObj.updated_at = '2024-01-01 01:01:01'

        fields.push(responseObj)
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação do talhão',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/ndvi',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        const startDate = moment(responseObj.dates[0])
        const endDate = moment(responseObj.dates[1])

        const filteredNdvis = ndvis.filter((ndvi) => {
          const ndviDate = moment(ndvi.date)
          const ndviDateOnly = ndviDate.startOf('day')
          const isBetween = ndviDateOnly.isBetween(
            startDate,
            endDate,
            undefined,
            '[]'
          )
          return ndvi.field_id == responseObj.plot_id && isBetween
        })
        return APIFailureWrapper({
          content: filteredNdvis,
          errorMessage: 'Erro na listagem de NDVIs',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/talhoes/:field_id',
      result: ({ params }) => {
        fields.forEach((field) => {
          if (field.id === Number(params.field_id))
            field.deleted_at = '2024-01-04 01:01:01'
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção do talhão',
        })
      },
    },
    'on'
  ),
]

export { fieldRoutes }
