export default {
  Note: {
    new_note: 'Nueva anotación',
    edit: 'Editar anotación',
    filter_note: 'Filtrar anotaciones',
    notes: 'Notas',
    field_notebook: 'Cuaderno de campo',
    view_notes: 'Ver notas',
    note_category: 'Categoría de anotación',
    note_plot: 'Asociar al campo',
    select_plot: 'Selecione o talhao',
    register_success: 'Anotación registrada con éxito',
    delete_confirm:
      '¿Está seguro de que desea eliminar esta anotación? No podrá restaurarlo más tarde.',
    delete_success: 'Anotación eliminada con éxito',
    category: {
      buy_and_sell: 'Compra y venta',
      climate: 'Clima',
      diseases: 'Enfermedades',
      fertilizing: 'Fertilizar',
      machines: 'Máquinas',
      nematodes: 'Nematodos',
      others: 'Otros',
      pests: 'Plagas',
    },
    loading: {
      erro: 'Error al cargar notas',
    },
    no_notes: 'Todavía no tienes ninguna nota',
    add_first: 'Registra tu primera nota de campo',
    add_first_msg:
      'Empieza a registrar digitalmente el día a día de tu cultivo, así tendrás un historial de todo lo que sucede.',
  },
}
