export default {
  Plans: {
    header_text:
      'A melhor solução para <span>aumentar sua produtividade</span>',
    have_this_plan: 'Você já possui esse plano',
    request_plan: 'Solicitar plano',
    per_month: '/mês',
    per_month_per_hectare: '/mês/ha',
    large_farms: 'grandes fazendas',
    pronaf: 'pronaf',
  },
}
