const Module = () =>
  import(/* webpackChunkName: "plans-module" */ '@/modules/plans/Module.vue')
const PlansHome = () =>
  import(
    /* webpackChunkName: "plans-module" */ '@/modules/plans/views/PlansHome.vue'
  )

const routes = [
  {
    path: '/plans',
    component: Module,
    children: [
      {
        path: '/',
        component: PlansHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
