export default {
  Plot: {
    Home: {
      title: 'Lotes',
    },
    Categories: {
      planting: 'Siembra',
      notes: 'Notas',
      weather: 'Clima',
    },
    Infos: {
      title: 'Mi Lote',
      area: 'Area sembrada',
      sown: 'sembrado',
      total: 'Total',
      hectare: 'ha',
    },
    Buttons: {
      new_sowing: '+ Sembrado',
      new_note: '+ Nota',
      new_plot: '+ Lote',
    },
    Planting: {
      sown_area: 'Área Sembrada',
      sown_in: 'Sembrado En',
      will_harvest_in: 'Cosechará En',
      no_prediction: 'Sin pronóstico',
      view_more: 'Ver más',
    },
    Notes: {
      categories: {
        climate: 'Clima',
        buy_and_sell: 'Compra y venta',
        diseases: 'Enfermedades',
        fertilizing: 'Fertlizar',
        machines: 'Máquinas',
        nematodes: 'Nematodos',
        pests: 'Plagas',
        others: 'Otros',
      },
    },
    Warnings: {
      note: 'No tienes notas asociadas con este campo.',
      planting: 'No tienes sembrados registradas en este campo.',
    },
    Crop: {
      crop: {
        soybean: 'Soja',
        wheat: 'Trigo',
      },
    },
    Ndvi: {
      level_label: 'Índice de Vegetación',
      low: 'Bajo',
      medium: 'Promedio',
      high: 'Alto',
    },
    NdviUpsell: {
      message: '¿Sabías que DigiFarmz ofrece soluciones de mapa NDVI?',
      what_is: '¿Qué es NDVI?',
      contract: 'Contratar',
      contract_ndvi:
        'Hola, me gustaría suscribirme a NDVI para mi cuenta DigiFarmz',
    },
    Upsell: {
      upgrade: 'Se requiere actualización',
      message:
        'Monitoriza la evolución de tu cultivo, monitorizando todas sus aplicaciones y asegurando más productividad.',
      button: 'Actualización',
    },
    Exceptions: {
      get_plots: 'Error al buscar el lote',
      create_plot: 'Error al crear el lote',
      create_plots: 'Error al crear los lotes',
      update_plot: 'Error al actualizar el lote',
    },
  },
}
