export default {
  Plot: {
    Home: {
      title: 'Talhões',
    },
    Categories: {
      planting: 'Semeaduras',
      notes: 'Anotações',
      weather: 'Tempo',
    },
    Infos: {
      title: 'Meu Talhão',
      area: 'Área semeada',
      sown: 'Semeada',
      total: 'Total',
      hectare: 'ha',
    },
    Buttons: {
      new_sowing: '+ Semeadura',
      new_note: '+ Anotação',
      new_plot: '+ Talhôes',
    },
    Planting: {
      sown_area: 'Área Semeada',
      sown_in: 'Data Semeadura',
      will_harvest_in: 'Previsão de Colheita',
      no_prediction: 'Sem Previsão',
      view_more: 'Ver mais',
    },
    Notes: {
      categories: {
        climate: 'Clima',
        buy_and_sell: 'Compra e venda',
        diseases: 'Doenças',
        fertilizing: 'Adubação',
        machines: 'Máquinas',
        nematodes: 'Nematóides',
        pests: 'Pragas',
        others: 'Outros',
      },
    },
    Warnings: {
      note: 'Você não possui anotações associados a este talhão.',
      planting: 'Você não possui semeaduras registradas neste talhão.',
    },
    Crop: {
      crop: {
        soybean: 'Soja',
        wheat: 'Trigo',
      },
    },
    Ndvi: {
      level_label: 'Índice de Vegetação',
      low: 'Baixo',
      medium: 'Médio',
      high: 'Alto',
    },
    NdviUpsell: {
      message: 'Você sabia que a DigiFarmz oferece soluções de mapa NDVI?',
      what_is: 'O que é NDVI?',
      contract: 'Contratar',
      contract_ndvi:
        'Olá, gostaria de contratar o NDVI para minha conta DigiFarmz',
    },
    Upsell: {
      upgrade: 'Necessário Upgrade',
      message:
        'Acompanhe a evolução da sua lavoura, acompanhando todas as suas aplicações e garantindo mais produtividade.',
      button: 'Fazer Upgrade',
    },
    Exceptions: {
      get_plots: 'Erro ao buscar os talhões',
      create_plot: 'Erro ao criar o talhão',
      create_plots: 'Erro ao criar os talhões',
      update_plot: 'Erro ao atualizar o talhão',
    },
  },
}
