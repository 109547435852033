export default {
  Plans: {
    header_text:
      'La mejor solución para <span>aumentar tu productividad</span>',
    have_this_plan: 'Ya tienes este plan',
    request_plan: 'Solicitar plan',
    per_month: '/mes',
    per_month_per_hectare: '/mes/ha',
    large_farms: 'grandes haciendas',
    pronaf: 'pronaf',
  },
}
