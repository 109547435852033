export default {
  report: {
    printed_day: 'IMPRESSO DIA',
    account: 'CONTA',
    farm_report: 'RELATÓRIO DA FAZENDA',
    localization: 'LOCALIZAÇÃO',
    who_printed: 'QUEM IMPRIMIU',
    total_of_sprays: 'MÉDIA DE APLICAÇÕES',
    average_harvest_per_plot: 'PRODUTIVIDADE',
    total_value_of_defensives_used: 'VALOR GASTO COM FUNGICIDA',
    report: 'Relatório',
    fungicides_applied: 'Fungicidas Aplicados',
    product: 'PRODUTO',
    quantity: 'DOSE',
    liters: 'LITROS',
    kilos: 'KG',
    value: 'VALOR',
    variety: 'CULTIVAR',
    company: 'TITULAR',
    amount_per_hectare: 'HECTARES SEMEADOS',
    sprays: 'Aplicações',
    already_applied_on_the_day: 'JÁ APLICADO NO DIA',
    next_spray: 'PRÓX. APLICAÇÃO',
    to_confirm: 'A confirmar',
    defensive: 'FUNGICIDA',
    performance: 'Performance',
    genetics: 'GENÉTICA',
    culture: 'CULTURA',
    seeding_area: 'ÁREA SEMEADA',
    productivity: 'PRODUTIVIDADE MÉDIA',
    plot: 'TALHÃO',
    area: 'ÁREA',
    not_sprays_avaliable: 'Não há nenhuma aplicação para esta semeadura',
    harvest_unavailable: 'Preencha os dados da colheita',
    btn_season_report: 'imprimir relatório',
    area_sowing: 'ÁREA SEMEADA',
    confirm: 'Confirmado',
    varieties_used: 'Cultivares Semeadas',
    production: 'PRODUÇÃO TOTAL',
  },
}
