import moment from 'moment'
import { noteCategories, notes } from '@/modules/notes/mock/notesSeeder'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

const noteRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/farms/:id/fields-notes',
      result: ({ params, queryParams }) => {
        let filteredNotes = notes.filter((note) => note.farm.id == params.id)
        if (queryParams.filter) {
          const filters = {}
          const pairs = queryParams.filter.split(',')
          pairs.forEach((pair) => {
            const [key, value] = pair.split(':')
            filters[key] = value
          })
          if (filters.plot_id) {
            filteredNotes = filteredNotes.filter(
              (note) => note.plot.id == filters.plot_id
            )
          }
          if (filters.category_id) {
            filteredNotes = filteredNotes.filter(
              (note) => note.category.id == filters.category_id
            )
          }
          if (filters.created_by) {
            filteredNotes = filteredNotes.filter(
              (note) => note.created_by.id == filters.created_by
            )
          }
          if (filters.created_at) {
            filteredNotes = filteredNotes.filter(
              (note) =>
                moment(note.created_at).format('YYYY-MM-DD') ==
                filters.created_at
            )
          }
        }
        return APIFailureWrapper({
          content: filteredNotes,
          errorMessage: 'Erro na listagem de anotações da fazenda',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/notes-categories',
      result: () => {
        return APIFailureWrapper({
          content: noteCategories,
          errorMessage: 'Erro na listagem de categorias de anotações',
        })
      },
    },
    'on'
  ),
]

export { noteRoutes }
