const FieldModule = () =>
  import(/* webpackChunkName: "field-module" */ '@/modules/plot/Module.vue')
const FieldHome = () =>
  import(
    /* webpackChunkName: "field-module" */ '@/modules/plot/views/FieldHome.vue'
  )

const routes = [
  {
    path: '/plot',
    component: FieldModule,
    children: [
      {
        path: '/',
        component: FieldHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
