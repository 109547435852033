const FieldModule = () =>
  import(
    /* webpackChunkName: "payment-module" */ '@/modules/payment/Module.vue'
  )
const PaymentHome = () =>
  import(
    /* webpackChunkName: "payment-module" */ '@/modules/payment/views/PaymentHome.vue'
  )

const routes = [
  {
    path: '/payment',
    component: FieldModule,
    children: [
      {
        path: '/',
        component: PaymentHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
