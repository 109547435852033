export default {
  Plans: {
    header_text: 'The best solution to <span>increase your productivity</span>',
    have_this_plan: 'You already have this plan',
    request_plan: 'Request plan',
    per_month: '/month',
    per_month_per_hectare: '/month/acre',
    large_farms: 'large farms',
    pronaf: 'pronaf',
  },
}
