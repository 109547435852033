import api from '@/services/api'

export default {
  namespaced: true,

  state: {
    filteredNotes: [],
    loading: false,
    notes: [],
    notesCategories: [],
  },

  getters: {
    filteredNotes(state) {
      return state.filteredNotes
    },
    loading(state) {
      return state.loading
    },
    notes(state) {
      return state.notes
    },
    notesCategories(state) {
      return state.notesCategories
    },
  },

  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_NOTES(state, notes) {
      state.notes = notes
    },
    SET_FILTERED_NOTES(state, filteredNotes) {
      state.filteredNotes = filteredNotes
    },
    SET_NOTE_CATEGORIES(state, categories) {
      state.notesCategories = categories
    },
  },

  actions: {
    async fetchNotes({ commit }, { farmId, filter = '' }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await api.notes.all(farmId, filter)
        if (filter) {
          commit('SET_FILTERED_NOTES', data)
        } else {
          commit('SET_NOTES', data)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchNoteCategories({ commit }) {
      try {
        const { data } = await api.notes.categories.get()
        commit('SET_NOTE_CATEGORIES', data)
      } catch (e) {
        throw new Error(e)
      }
    },
  },
}
