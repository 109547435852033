export default {
  report: {
    printed_day: 'IMPRESIÓN DEL DÍA',
    account: 'CUENTA',
    farm_report: 'INFORME DE HACIENDA',
    localization: 'LOCALIZACIÓN',
    who_printed: 'QUIÉN IMPRIME',
    total_of_sprays: 'PROMEDIO DE SOLICITUDES',
    average_harvest_per_plot: 'PRODUCTIVIDAD',
    total_value_of_defensives_used: 'CANTIDAD GASTADA EN FUNGICIDA',
    report: 'Informe',
    fungicides_applied: 'Fungicidas Aplicado',
    product: 'PRODUCTO',
    quantity: 'DOSE',
    liters: 'LITROS',
    kilos: 'KG',
    value: 'VALOR',
    variety: 'CULTIVAR',
    company: 'TITULAR',
    amount_per_hectare: 'HECTÁREAS SEMBRADAS',
    sprays: 'Aplicaciones',
    already_applied_on_the_day: 'YA APLICADO EN EL DIA',
    next_spray: 'PRÓXIMA APLICACIÓN',
    to_confirm: 'Para confirmar',
    defensive: 'FUNGICIDA',
    performance: 'Rendimento',
    genetics: 'GENÉTICA',
    culture: 'CULTURA',
    seeding_area: 'ÁREA DE SEMILLA',
    productivity: 'RENDIMENTO MEDIO',
    plot: 'LOTE',
    area: 'ÁREA',
    not_sprays_avaliable: 'No hay aplicación para esta siembra',
    harvest_unavailable: 'Complete los datos de cosecha para ver',
    btn_season_report: 'imprimir informe',
    area_sowing: 'ÁREA SEMILLA',
    confirm: 'Confirmado',
    varieties_used: 'Cultivares Sembrados',
    production: 'PRODUCCIÓN TOTAL',
  },
}
