export default {
  Performance: {
    Home: {
      title: 'Performance',
    },
    Productivity: {
      average: 'Produtividade média',
      total: 'Produtividade total',
      highest_average: 'Maior produtividade média',
      lowest_average: 'Menor produtividade média',
    },
    Types: {
      plantings: 'semeaduras',
      varieties: 'cultivares',
      sprays: 'aplicações',
    },
    Messages: {
      withoutHarvests:
        'Esta fazenda ainda não possui colheitas na safra selecionada.',
      erro: 'Esta fazenda ainda não possui colheitas.',
    },
    Tabs: {
      actual: 'Atual',
    },
    Plot: {
      products_cost: 'Custo de produtos',
      sprays: 'Aplicações',
      medium_cycle: 'Ciclo médio',
    },
  },
}
