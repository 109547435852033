const planTexts = {
  Mensal: 'Cobrado mensalmente',
  Semestral: 'Cobrado a cada 6 meses',
  Anual: 'Cobrado anualmente',
}

const planInfos = {
  // priority_level
  1: {
    name: 'Light',
    description:
      'No plano Light você tem acesso ilimitado a todas as informações do catálogo',
    approved_payment_message: 'Desfrute de todo o catálogo, sem restrições',
  },
  2: {
    name: 'Pro',
    description:
      'No plano Pro você planeja as aplicações de insumos personalizadas pela sua fazenda',
    approved_payment_message: 'Desfrute do uso dos planejamentos de aplicações',
  },
  3: {
    name: 'Digifarmz',
    description: '',
    approved_payment_message: '',
  },
  4: {
    name: 'Full',
    description: '',
    approved_payment_message: '',
  },
}

export { planInfos, planTexts }
