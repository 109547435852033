export default {
  Performance: {
    Home: {
      title: 'Performance',
    },
    Productivity: {
      average: 'Productividad media',
      total: 'Productividad total',
      highest_average: 'Mayor productividad media',
      lowest_average: 'Menor productividad media',
    },
    Types: {
      plantings: 'Siembra',
      varieties: 'Cultivares',
      sprays: 'Aplicaciones',
    },
    Messages: {
      withoutHarvests:
        'Esta finca aún no tiene cultivos en la temporada seleccionada.',
      erro: 'No se pudieron cargar los datos de la granja.',
    },
    Tabs: {
      actual: 'Actual',
    },
    Plot: {
      products_cost: 'Costo de productos',
      sprays: 'Aplicaciones',
      medium_cycle: 'Ciclo media',
    },
  },
}
