import moment from 'moment'

const fields = [
  {
    id: 1,
    nome: 'Talhão com NDVI - 1',
    area: 50,
    area_calculada: null,
    clay_soil: 0,
    coordenadas_lat:
      '-28.22470038155091|-28.222677383206012|-28.216230001879786|-28.21536023735397|-28.21483081200027|-28.215643856999478|-28.21775207275332|-28.218635998364036|-28.218643088646846|-28.219516371544948|-28.220085360550943|-28.22055892489332|-28.22157089206055|-28.222057965233315|-28.22267200240186|-28.2227854425817|-28.22259637554833|-28.222898882640994|-28.22368619679408|-28.22421219681251',
    coordenadas_lng:
      '-52.38994586846154|-52.3844097890548|-52.38771427056115|-52.388014677970816|-52.38782155892174|-52.388765696494985|-52.389055375068594|-52.388813976257254|-52.388392869441915|-52.38826814672272|-52.38781954726498|-52.387530874519754|-52.38815901434343|-52.38810830382924|-52.388808611839224|-52.38958108803551|-52.38992441078942|-52.39018190285485|-52.3904072084121|-52.390240911453176',
    created_at: '2024-01-01 01:01:01',
    deleted_at: null,
    elevation: null,
    enabled: 1,
    fazenda_id: 1,
    latitude: '-28.22470038155091',
    longitude: '-52.38994586846154',
    ndviEnabled: 1,
    soil_type_id: null,
    updated_at: '2024-01-01 01:01:01',
  },
  {
    id: 2,
    nome: 'Talhão sem NDVI - 1',
    area: 20,
    area_calculada: null,
    clay_soil: 0,
    coordenadas_lat:
      '-27.2712533735|-27.2715935111|-27.271675067|-27.2717235144|-27.2717925813|-27.2719239257|-27.2719900589|-27.2720069904|-27.2719563637|-27.2719685175|-27.2717925813|-27.2713193391|-27.2709966358|-27.2706387285|-27.2706312686|-27.2706300952|-27.2706174385|-27.2706004232|-27.2705796361|-27.270556502|-27.270535212|-27.2705207113|-27.2705057077|-27.2704927158|-27.270486597|-27.2704924643|-27.2705079708|-27.2705682367|-27.2706537321|-27.2707812209|-27.2709319275|-27.2709020879|-27.2706290055|-27.270257771|-27.269923836|-27.2697523423|-27.2697043978|-27.2695034835',
    coordenadas_lng:
      '-53.6572443321|-53.6571684759|-53.65712757219999|-53.6570376344|-53.65675139239999|-53.6564725265|-53.6563142762|-53.6562303733|-53.6560494918|-53.65598855540001|-53.6558767408|-53.6559361685|-53.6559836939|-53.6560752243|-53.6561051477|-53.6561386753|-53.6563669145|-53.6564182118|-53.6564654857|-53.656507479|-53.6565424316|-53.6565759592|-53.6566074751|-53.6566345487|-53.65665977820001|-53.65668584590001|-53.6567139253|-53.65676689890001|-53.6567996722|-53.65691408509999|-53.6570380535|-53.6570531409|-53.65704199299999|-53.6570321023|-53.657420855|-53.65778144450001|-53.6580420379|-53.6585951596',
    created_at: '2024-01-02 01:01:01',
    deleted_at: null,
    elevation: null,
    enabled: 1,
    fazenda_id: 1,
    latitude: '-27.2712533735',
    longitude: '-53.6572443321',
    ndviEnabled: 0,
    soil_type_id: null,
    updated_at: '2024-01-02 01:01:01',
  },
  {
    id: 3,
    nome: 'Talhão sem NDVI - 2',
    area: 30,
    area_calculada: null,
    clay_soil: 0,
    coordenadas_lat:
      '-29.782378433658852|-29.782229445504598|-29.78375656357477',
    coordenadas_lng:
      '-55.825310071480565|-55.82264932013779|-55.822563489449315',
    created_at: '2024-01-03 01:01:01',
    deleted_at: null,
    elevation: null,
    enabled: 1,
    fazenda_id: 1,
    latitude: '-29.782378433658852',
    longitude: '-55.825310071480565',
    ndviEnabled: 0,
    soil_type_id: null,
    updated_at: '2024-01-03 01:01:01',
  },
  {
    id: 4,
    nome: 'Talhão - Desativado',
    area: 25,
    area_calculada: null,
    clay_soil: 0,
    coordenadas_lat:
      '-27.2718515061|-27.2719878796|-27.2721449565|-27.2723820806|-27.2725899518|-27.2727415804|-27.2727783769|-27.2728522215|-27.27292614990001|-27.2728942148|-27.2726819851|-27.2725964896|-27.2724880278|-27.2721846029|-27.2720001172|-27.2719124425|-27.2719000373|-27.2718359996|-27.2716883942|-27.2715511825|-27.2711941134|-27.2709947079|-27.2706509661|-27.2702691704|-27.270045625|-27.2699294519|-27.2697053198|-27.2696590517|-27.2695989534|-27.2696453054|-27.26981638|-27.27001846770001|-27.270473605|-27.2705503833|-27.2707245592|-27.2708576638|-27.2711195145|-27.2711695544|-27.2711998969|-27.2712117992',
    coordenadas_lng:
      '-53.65442541429999|-53.6548528075|-53.6551346071|-53.6554081086|-53.6554419715|-53.6555259582|-53.6556715518|-53.6557277106|-53.65590146740001|-53.6559960991|-53.6562374141|-53.65639918479999|-53.6564954929|-53.6565670744|-53.6568772886|-53.6571253091|-53.6572133191|-53.6576470826|-53.6578712985|-53.6579093523|-53.65790532900001|-53.6578977015|-53.6578851286|-53.6580634955|-53.6582150403|-53.6583641544|-53.6586896237|-53.6587178707|-53.6586488038|-53.6585784797|-53.65833716470001|-53.65806374700001|-53.6578101944|-53.6577808578|-53.6577498447|-53.65776283669999|-53.65777029659999|-53.6577183288|-53.6575249583|-53.65739788860001',
    created_at: '2024-01-04 01:01:01',
    deleted_at: null,
    elevation: null,
    enabled: 0,
    fazenda_id: 1,
    latitude: '-27.2718515061',
    longitude: '-53.65442541429999',
    ndviEnabled: 0,
    soil_type_id: null,
    updated_at: '2024-01-04 01:01:01',
  },
  {
    id: 5,
    nome: 'Talhão - Deletado',
    area: 25,
    area_calculada: null,
    clay_soil: 0,
    coordenadas_lat:
      '-27.2718515061|-27.2719878796|-27.2721449565|-27.2723820806|-27.2725899518|-27.2727415804|-27.2727783769|-27.2728522215|-27.27292614990001|-27.2728942148|-27.2726819851|-27.2725964896|-27.2724880278|-27.2721846029|-27.2720001172|-27.2719124425|-27.2719000373|-27.2718359996|-27.2716883942|-27.2715511825|-27.2711941134|-27.2709947079|-27.2706509661|-27.2702691704|-27.270045625|-27.2699294519|-27.2697053198|-27.2696590517|-27.2695989534|-27.2696453054|-27.26981638|-27.27001846770001|-27.270473605|-27.2705503833|-27.2707245592|-27.2708576638|-27.2711195145|-27.2711695544|-27.2711998969|-27.2712117992',
    coordenadas_lng:
      '-53.65442541429999|-53.6548528075|-53.6551346071|-53.6554081086|-53.6554419715|-53.6555259582|-53.6556715518|-53.6557277106|-53.65590146740001|-53.6559960991|-53.6562374141|-53.65639918479999|-53.6564954929|-53.6565670744|-53.6568772886|-53.6571253091|-53.6572133191|-53.6576470826|-53.6578712985|-53.6579093523|-53.65790532900001|-53.6578977015|-53.6578851286|-53.6580634955|-53.6582150403|-53.6583641544|-53.6586896237|-53.6587178707|-53.6586488038|-53.6585784797|-53.65833716470001|-53.65806374700001|-53.6578101944|-53.6577808578|-53.6577498447|-53.65776283669999|-53.65777029659999|-53.6577183288|-53.6575249583|-53.65739788860001',
    created_at: '2024-01-04 01:01:01',
    deleted_at: '2024-01-04 01:01:01',
    elevation: null,
    enabled: 0,
    fazenda_id: 1,
    latitude: '-27.2718515061',
    longitude: '-53.65442541429999',
    ndviEnabled: 0,
    soil_type_id: null,
    updated_at: '2024-01-04 01:01:01',
  },
]

const ndvis = [
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().toISOString(),
    images: {
      ndvi: 'https://monitoredfields-prd-imagesbucket-1l67seopwr9ra.s3.eu-central-1.amazonaws.com/fc6db9fc941d692c656aafdaf0c0dcc94efb09dfb1e604d8a49eb9d9bd751797/745330/tiles/22/J/CP/2024/3/27/0/NDVI.png',
    },
    cloudCovering: 0,
  },
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().subtract(7, 'days').toISOString(),
    images: {
      ndvi: 'https://monitoredfields-prd-imagesbucket-1l67seopwr9ra.s3.eu-central-1.amazonaws.com/fc6db9fc941d692c656aafdaf0c0dcc94efb09dfb1e604d8a49eb9d9bd751797/745330/tiles/22/J/CP/2024/4/21/0/NDVI.png',
    },
    cloudCovering: 0,
  },
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().subtract(14, 'days').toISOString(),
    images: {
      ndvi: 'https://monitoredfields-prd-imagesbucket-1l67seopwr9ra.s3.eu-central-1.amazonaws.com/fc6db9fc941d692c656aafdaf0c0dcc94efb09dfb1e604d8a49eb9d9bd751797/745330/tiles/22/J/CP/2024/4/1/0/NDVI.png',
    },
    cloudCovering: 0,
  },
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().subtract(21, 'days').toISOString(),
    images: {
      ndvi: 'https://monitoredfields-prd-imagesbucket-1l67seopwr9ra.s3.eu-central-1.amazonaws.com/fc6db9fc941d692c656aafdaf0c0dcc94efb09dfb1e604d8a49eb9d9bd751797/745330/tiles/22/J/CP/2024/4/6/0/NDVI.png',
    },
    cloudCovering: 9.548041,
  },
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().subtract(28, 'days').toISOString(),
    images: {
      ndvi: 'https://monitoredfields-prd-imagesbucket-1l67seopwr9ra.s3.eu-central-1.amazonaws.com/fc6db9fc941d692c656aafdaf0c0dcc94efb09dfb1e604d8a49eb9d9bd751797/745330/tiles/22/J/CP/2024/4/26/0/NDVI.png',
    },
    cloudCovering: 0,
  },
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().subtract(35, 'days').toISOString(),
    images: {
      ndvi: 'https://monitoredfields-prd-imagesbucket-1l67seopwr9ra.s3.eu-central-1.amazonaws.com/fc6db9fc941d692c656aafdaf0c0dcc94efb09dfb1e604d8a49eb9d9bd751797/745330/tiles/22/J/CP/2024/5/6/0/NDVI.png',
    },
    cloudCovering: 0,
  },
  {
    field_id: fields[0].id,
    type: 'sentinel-s2-l2a',
    date: moment().subtract(42, 'days').toISOString(),
    images: {
      ndvi: '',
    },
    cloudCovering: 0,
  },
]

export { fields, ndvis }
