export default {
  clicked_upgrade_plan: 'clickedButton_UpgradePlan',
  clicked_talk_to_sales: 'clickedButton_TalkToSales',
  clicked_see_plans: 'clickedButton_SeePlans',

  openedModal: (modalName) => `openModal_${snakeToPascal(modalName)}`,
}

function _upperFirst(string) {
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length)
}

function snakeToPascal(string) {
  return string
    .split('_')
    .map((str) => {
      return _upperFirst(str.split('/').map(_upperFirst).join('/'))
    })
    .join('')
}
