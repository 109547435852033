<template>
  <gmap-map
    v-if="plot"
    style="width: 100%; height: 100%"
    map-type-id="satellite"
    :center="center"
    :options="customMapOptions"
    :zoom="disableUserInteraction ? 14 : 15"
  >
    <gmap-polygon
      v-if="paths.length"
      :key="polygonKey"
      :paths="paths"
      :editable="false"
      :options="polygon.polygonOptions"
    >
    </gmap-polygon>
  </gmap-map>
</template>

<script>
export default {
  name: 'Gmap',

  props: {
    plot: {
      type: Object,
      required: true,
    },
    disableUserInteraction: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      center: {
        lat: null,
        lng: null,
      },
      mapOptions: {
        fullscreenControl: true,
        mapTypeControl: false,
        streetViewControl: false,
      },
      polygon: {
        polygonOptions: {
          strokeColor: '#1976d2',
          fillColor: '#1976d2',
        },
      },
      polygonKey: 0,
    }
  },

  created() {
    if (this.plot.latitude && this.plot.longitude) {
      this.center = {
        lat: parseFloat(this.plot.latitude),
        lng: parseFloat(this.plot.longitude),
      }
    }
  },

  mounted() {
    this.polygonKey++
  },

  computed: {
    paths() {
      if (!this.plot.coordenadas_lat || !this.plot.coordenadas_lng) {
        return []
      }
      let lat = this.plot.coordenadas_lat.split('|')
      let lng = this.plot.coordenadas_lng.split('|')
      let polygon = []

      for (let x = 0; x < lat.length; x++) {
        polygon.push({
          lat: parseFloat(lat[x]),
          lng: parseFloat(lng[x]),
        })
      }

      return polygon
    },
    customMapOptions() {
      if (this.disableUserInteraction) {
        return {
          ...this.mapOptions,
          disableDefaultUI: true,
          keyboardShortcuts: false,
          scaleControl: false,
          zoomControl: false,
          fullscreenControl: false,
          disableDoubleClickZoom: true,
          gestureHandling: 'none',
        }
      } else {
        return this.mapOptions
      }
    },
  },
}
</script>
