export default {
  visitedPage: 'visitedPage_Notes',
  newNote: 'clickedButton_newNote',

  selectCategory: (category) => `noteCategory_${category}`,
  selectFilter: (filters) => `filtered_${filters}`,

  createdNote: 'createdNote',
  editedNote: 'editedNote',
  deletedNote: 'deletedNote',

  associateWithPlot: 'associateNote_withPlot',

  uploadedPhoto: 'uploadedPhoto',
  uploadedArchive: 'uploadedArchive',
}
