<template>
  <v-dialog width="393" :value="true" @click:outside="closeModal">
    <v-card class="light-plan-payment-modal">
      <v-card-text class="df-flex-xl df-flex-col modal">
        <section class="df-flex-sm df-flex-col modal__header">
          <h1>Plano {{ planInfos[planPriority].name }}</h1>
          <p>
            {{ planInfos[planPriority].description }}
          </p>
        </section>
        <section class="df-flex-m df-flex-col modal__content">
          <h2>Escolha seu plano</h2>
          <div v-if="loadingPlans" class="df-flex-l df-flex-col">
            <v-skeleton-loader
              v-for="(_, index) in vindiPlans.length || 3"
              :key="index"
              height="80px"
              type="image"
              width="auto"
            />
          </div>
          <div v-else-if="!vindiPlans.length" class="no-content">
            Não há planos para o modelo {{ planInfos[planPriority].name }} no
            momento
          </div>
          <div
            v-for="plan in vindiPlans"
            :key="plan.id"
            @click="selectPlan(plan.id)"
          >
            <plan-payment-card
              :frequency="plan.interval_name"
              :is-selected="isSelected(plan.id)"
              :most-popular="plan.interval_name === 'Anual'"
              :price="plan.plan_items[0].pricing_schema.short_format"
              :text="planTexts[plan.interval_name]"
            />
          </div>
          <df-button
            :disabled="disableButton"
            :loading="loadingPlans"
            @click="redirectToPayment"
          >
            Continuar
          </df-button>
          <df-button
            class="secondary-button"
            variant="text-secondary"
            @click="redirectToPlans"
          >
            Ver todos os planos
          </df-button>
          <p class="modal__cancel">Cancele a qualquer momento.</p>
          <p class="modal__terms">
            A assinatura renova automaticamente, a menos que a renovação
            automática seja desativada por pelo menos 24 horas antes do período
            de cobrança. Consulte nossos
            <a href="" target="_blank">Termos</a> para mais informações sobre o
            cancelamento e reembolso.
          </p>
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PlanPaymentCard from '@/modules/payment/components/PlanPaymentCard.vue'
import paymentService from '@/modules/payment/services/paymentService'
import { planInfos, planTexts } from '@/modules/payment/data/planPt'

export default {
  name: 'PlanPaymentChoicesModal',

  components: {
    DfButton,
    PlanPaymentCard,
  },

  props: {
    planPriority: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      loadingPlans: false,
      planInfos,
      planTexts,
      selectedPlan: {},
      vindiPlans: [],
    }
  },

  async created() {
    this.loadingPlans = true
    try {
      const { data } = await paymentService.getVindiPlans()
      const currentPlanName = planInfos[this.planPriority].name
      const regex = new RegExp(`\\b${currentPlanName}\\b`, 'i')
      const filteredPlans = data.data.filter(
        (plan) => plan.status === 'active' && regex.test(plan.name)
      )
      this.vindiPlans = filteredPlans
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingPlans = false
    }
  },

  computed: {
    disableButton() {
      return !this.selectedPlan?.id
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    isSelected(planId) {
      if (this.selectedPlan && this.selectedPlan.id) {
        return this.selectedPlan.id == planId
      }
      return false
    },
    redirectToPayment() {
      this.$router.push({
        path: '/payment',
        query: {
          plan_priority: this.planPriority,
          frequency: this.selectedPlan.interval_name,
        },
      })
      this.closeModal()
    },
    redirectToPlans() {
      this.$router.replace({
        path: '/plans',
      })
      this.closeModal()
    },
    selectPlan(planId) {
      this.selectedPlan = this.vindiPlans.find((plan) => plan.id == planId)
    },
  },
}
</script>

<style lang="scss" scoped>
.light-plan-payment-modal {
  border-radius: 8px;

  .modal {
    padding: 24px;

    .modal__header {
      text-align: center;
      font-family: 'Rubik';
      font-style: normal;
      h1 {
        color: #1a2b46;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
      p {
        color: #5b6459;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .modal__content {
      h2 {
        color: #1a2b46;
        font-family: 'Rubik';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .no-content {
        padding: 24px;
        border: 1px solid #e6e9e6;
        border-radius: 8px;
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
      }
      .modal__cancel {
        color: #5b6459;
        text-align: center;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .modal__terms {
        color: #5b6459;
        text-align: center;
        font-family: 'Rubik';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        a {
          color: #4a76bc;
          text-decoration-line: underline;
        }
      }
    }
  }
}
::v-deep .secondary-button p {
  color: #39af49;
}
</style>
