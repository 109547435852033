import moment from 'moment'
import { fields } from '@/modules/plot/mock/fieldsSeeds'
import { farms } from '@/services/__mocks__/farmsSeeds'

const noteCategories = [
  {
    id: 1,
    name: 'buy_and_sell',
  },
  {
    id: 2,
    name: 'climate',
  },
  {
    id: 3,
    name: 'diseases',
  },
  {
    id: 4,
    name: 'fertilizing',
  },
  {
    id: 5,
    name: 'machines',
  },
  {
    id: 6,
    name: 'nematodes',
  },
  {
    id: 8,
    name: 'pests',
  },
  {
    id: 7,
    name: 'others',
  },
]

const farmNDVIFields = fields.filter((field) => field.fazenda_id == farms[0].id)

const notes = [
  {
    id: 1,
    title: 'Clima ruim',
    description: 'Atenção para amanhã! O dia estará ruim para colheita',
    category: noteCategories[1],
    farm: {
      id: farms[0].id,
      name: farms[0].name,
    },
    plot: {
      id: farmNDVIFields[0].id,
      name: farmNDVIFields[0].name,
    },
    created_by: {
      id: 1,
      name: 'Plano Full - Teste Interno',
    },
    edited_by: {
      id: null,
      name: null,
    },
    files: [],
    created_at: moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 2,
    title: 'Doença na soja',
    description: 'Começou a aparecer doença, elaborar plano de ação',
    category: noteCategories[2],
    farm: {
      id: farms[0].id,
      name: farms[0].name,
    },
    plot: {
      id: farmNDVIFields[0].id,
      name: farmNDVIFields[0].name,
    },
    created_by: {
      id: 1,
      name: 'Plano Full - Teste Interno',
    },
    edited_by: {
      id: null,
      name: null,
    },
    files: [],
    created_at: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 3,
    title: 'Faltou material',
    description: 'Durante adubação faltou adubo para os últimos 10m²',
    category: noteCategories[3],
    farm: {
      id: farms[0].id,
      name: farms[0].name,
    },
    plot: {
      id: farmNDVIFields[0].id,
      name: farmNDVIFields[0].name,
    },
    created_by: {
      id: 1,
      name: 'Plano Full - Teste Interno',
    },
    edited_by: {
      id: null,
      name: null,
    },
    files: [],
    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 4,
    title: 'Revisão do maquinário',
    description: 'O prazo para inspeção das máquinas está próximo',
    category: noteCategories[4],
    farm: {
      id: farms[0].id,
      name: farms[0].name,
    },
    plot: {
      id: farmNDVIFields[0].id,
      name: farmNDVIFields[0].name,
    },
    created_by: {
      id: 1,
      name: 'Plano Full - Teste Interno',
    },
    edited_by: {
      id: null,
      name: null,
    },
    files: [],
    created_at: moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 5,
    title: 'Clima bom para colheita',
    description: 'A previsão errou, e podemos realizar a colheita',
    category: noteCategories[1],
    farm: {
      id: farms[0].id,
      name: farms[0].name,
    },
    plot: {
      id: farmNDVIFields[0].id,
      name: farmNDVIFields[0].name,
    },
    created_by: {
      id: 1,
      name: 'Plano Full - Teste Interno',
    },
    edited_by: {
      id: null,
      name: null,
    },
    files: [],
    created_at: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
  },
]

export { notes, noteCategories }
