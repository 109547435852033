const Module = () =>
  import(
    /* webpackChunkName: "performance-module" */ '@/modules/performance/Module.vue'
  )
const PerformanceHome = () =>
  import(
    /* webpackChunkName: "performance-module" */ '@/modules/performance/views/PerformanceHome.vue'
  )

const routes = [
  {
    path: '/performance',
    component: Module,
    children: [
      {
        path: '/',
        component: PerformanceHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
