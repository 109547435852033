export default {
  Plot: {
    Home: {
      title: 'Plots',
    },
    Categories: {
      planting: 'Planting',
      notes: 'Notes',
      weather: 'Weather',
    },
    Infos: {
      title: 'My Plot',
      area: 'Sown area',
      sown: 'Sown',
      total: 'Total',
      hectare: 'ha',
    },
    Buttons: {
      new_sowing: '+ Sowing',
      new_note: '+ Note',
      new_plot: '+ Plot',
    },
    Planting: {
      sown_area: 'Sown Area',
      sown_in: 'Sown In',
      will_harvest_in: 'Will Harverst In',
      no_prediction: 'No Prediction',
      view_more: 'View more',
    },
    Notes: {
      categories: {
        climate: 'Climate',
        buy_and_sell: 'Buy and sell',
        diseases: 'Diseases',
        fertilizing: 'Fertlizing',
        machines: 'Machines',
        nematodes: 'Nematodes',
        pests: 'Pests',
        others: 'Others',
      },
    },
    Warnings: {
      note: 'You do not have notes associated with this field.',
      planting: 'You have no seedings registered in this field.',
    },
    Crop: {
      crop: {
        soybean: 'Soybean',
        wheat: 'Wheat',
      },
    },
    Ndvi: {
      level_label: 'Vegetation Index',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
    },
    NdviUpsell: {
      message: 'Did you know that DigiFarmz offers NDVI map solutions?',
      what_is: 'What is NDVI?',
      contract: 'Contract',
      contract_ndvi:
        'Hello, I would like to subscribe to NDVI for my DigiFarmz account',
    },
    Upsell: {
      upgrade: 'Upgrade required',
      message:
        'Monitor the evolution of your crop, monitoring all its applications and ensuring more productivity.',
      button: 'Upgrade',
    },
    Exceptions: {
      get_plots: 'Error when fetching plots',
      create_plot: 'Error when creating plot',
      create_plots: 'Error when creating plots',
      update_plot: 'Error when updating plot',
    },
  },
}
