export default {
  Performance: {
    Home: {
      title: 'Performance',
    },
    Productivity: {
      average: 'Average Productivity',
      total: 'Productivity total',
      highest_average: 'Higher average productivity',
      lowest_average: 'Lower average productivity',
    },
    Types: {
      plantings: 'Plantings',
      varieties: 'Varieties',
      sprays: 'Sprays',
    },
    Messages: {
      withoutHarvests:
        'This farm does not yet have harvests in the selected season.',
      erro: 'This farm does not have harvest registered.',
    },
    Tabs: {
      actual: 'Current',
    },
    Plot: {
      products_cost: 'Cost of products',
      sprays: 'Sprays',
      medium_cycle: 'Average cycle',
    },
  },
}
