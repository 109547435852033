const Module = () =>
  import(
    /* webpackChunkName: "reports-module" */ '@/modules/reports/Module.vue'
  )
const Programa = () =>
  import(
    /* webpackChunkName: "reports-module" */ '@/modules/reports/views/Programa.vue'
  )
const SeasonReport = () =>
  import(
    /* webpackChunkName: "reports-module" */ '@/modules/reports/views/SeasonReport.vue'
  )

const routes = [
  {
    path: '/reports',
    component: Module,
    children: [
      {
        path: 'programa',
        component: Programa,
      },
      {
        path: 'season-report',
        component: SeasonReport,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
