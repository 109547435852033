<template>
  <div class="d-flex flex-column content__payment" :class="isCheckboxSelected">
    <p v-if="mostPopular" class="popular__title">Mais popular</p>
    <div class="df-flex-l align-center payment__wrapper">
      <div class="payment__checkbox" :class="isCheckboxSelected"></div>
      <div class="df-flex-sm df-flex-col payment__infos">
        <div class="d-flex justify-space-between">
          <h3>{{ frequency }}</h3>
          <h3>{{ price }}</h3>
        </div>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanPaymentCard',

  props: {
    frequency: {
      required: true,
      type: String,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
    mostPopular: {
      default: false,
      type: Boolean,
    },
    price: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },

  computed: {
    isCheckboxSelected() {
      return this.isSelected ? 'selected' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.content__payment {
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  cursor: pointer;

  &.selected {
    border: 1px solid #39af49;
  }
  .payment__wrapper {
    padding: 16px;
  }
  .popular__title {
    padding: 8px 16px;
    border-radius: 8px 8px 0px 0px;
    background-color: #d8f2dc;
    color: #39af49;
    text-align: center;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
.payment__checkbox {
  height: 16px;
  width: 16px;
  border-radius: 9999px;
  border: 1px solid #aab2a9;
  background-color: #f8f9f8;
}
.payment__infos {
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  h3 {
    color: #1a2b46;
    font-weight: 500;
  }
  p {
    color: #5b6459;
    font-weight: 400;
  }
}
.selected {
  border: 5px solid #39af49;
}
</style>
