import http from '@/utils/http'
import Cookies from 'js-cookie'
import axios from 'axios'
import { router } from '@/router'

const paymentService = axios.create({ baseURL: process.env.VUE_APP_VINDI_URL })

paymentService.interceptors.request.use((config) => {
  const token = Cookies.get('token')

  if (token) config.headers['Authorization'] = `Bearer ${token}`
  // XXX: redireciona para login se não tiver token (separation of concerns fail)
  else router.push('/login')

  return config
})

const payment = {
  getVindiPaymentMethods: () => paymentService.get('/payments-methods'),
  getVindiPlans: () => paymentService.get('/plans'),
  createCustomer: (params) => paymentService.post('/customers', params),
  createSubscription: (params) => paymentService.post('/subscriptions', params),
  createBill: (params) => paymentService.post('/bills', params),
  createPaymentProfile: (params) =>
    paymentService.post('/payment-profiles', params),
  updateUserPlan: (params) =>
    http.put(
      `/plan/${params.plan_id}/account/${params.account_id}/interval/${params.interval}/update-plan`
    ),
  updateVindiId: (params) =>
    http.put('/payments/vindi/updated-vindi-id', params),
}

export default payment
