export default {
  Note: {
    new_note: 'Nova anotação',
    edit: 'Editar anotação',
    filter_note: 'Filtrar anotações',
    notes: 'Anotações',
    field_notebook: 'Caderno de campo',
    view_notes: 'Ver anotações',
    note_category: 'Categoria da anotação',
    note_plot: 'Associar a talhão',
    select_plot: 'Selecione o talhao',
    register_success: 'Anotação registrada com sucesso',
    delete_confirm:
      'Você tem certeza que deseja excluir esta anotação? Você não irá conseguir restaurá-la posteriormente.',
    delete_success: 'Anotação eliminada com sucesso',
    category: {
      buy_and_sell: 'Compra e venda',
      climate: 'Clima',
      diseases: 'Doenças',
      fertilizing: 'Adubação',
      machines: 'Máquinas',
      nematodes: 'Nematóides',
      others: 'Outros',
      pests: 'Pragas',
    },
    loading: {
      erro: 'Erro ao carregar anotações',
    },
    no_notes: 'Você ainda não tem anotações',
    add_first: 'Cadastre sua primeira anotação de campo',
    add_first_msg:
      'Comece a registrar o dia a dia da sua lavoura de forma digital, assim você terá o histórico de tudo que acontece.',
  },
}
