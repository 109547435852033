const paymentMethods = {
  data: [
    {
      id: 32310,
      public_name: 'Cartão de crédito',
      name: 'Cartão de crédito',
      code: 'credit_card',
      type: 'PaymentMethod::CreditCard',
      status: 'active',
      settings: {
        automatic_bill_charge: false,
        subscription_billing_delay_days: '0',
        soft_descriptor: '',
        attempt_near_pay_days: true,
        payment_profile_attempt: true,
        attempt_fallback_acquirer: true,
      },
      set_subscription_on_success: 'set_without_authorization',
      allow_as_alternative: false,
      payment_companies: [
        {
          id: 1,
          name: 'MasterCard',
          code: 'mastercard',
        },
        {
          id: 2,
          name: 'Visa',
          code: 'visa',
        },
        {
          id: 11,
          name: 'Elo',
          code: 'elo',
        },
        {
          id: 12,
          name: 'Hipercard',
          code: 'hipercard',
        },
        {
          id: 3,
          name: 'American Express',
          code: 'american_express',
        },
        {
          id: 9,
          name: 'Diners Club',
          code: 'diners_club',
        },
        {
          id: 22,
          name: 'JCB',
          code: 'jcb',
        },
      ],
      maximum_attempts: 5,
      created_at: '2019-03-22T11:55:12.000-03:00',
      updated_at: '2024-04-10T18:03:17.000-03:00',
    },
    {
      id: 32311,
      public_name: 'Dinheiro',
      name: 'Dinheiro',
      code: 'cash',
      type: 'PaymentMethod::Cash',
      status: 'inactive',
      settings: {
        due_days: 3,
      },
      set_subscription_on_success: 'do_not_set',
      allow_as_alternative: false,
      payment_companies: [],
      maximum_attempts: null,
      created_at: '2019-03-22T11:55:12.000-03:00',
      updated_at: '2019-03-22T11:55:12.000-03:00',
    },
    {
      id: 32817,
      public_name: 'Boleto bancário',
      name: 'Boleto bancário',
      code: 'bank_slip',
      type: 'PaymentMethod::BankSlip',
      status: 'active',
      settings: {
        require_gateway: true,
        due_days: '3',
        dynamic: true,
        additional_instructions: '',
      },
      set_subscription_on_success: 'do_not_set',
      allow_as_alternative: false,
      payment_companies: [],
      maximum_attempts: null,
      created_at: '2019-04-12T17:48:32.000-03:00',
      updated_at: '2022-05-02T13:09:24.000-03:00',
    },
  ],
}

const paymentPlans = {
  data: [
    {
      id: 97489,
      name: 'Plataforma Digifarmz',
      interval: 'months',
      interval_count: 12,
      billing_trigger_type: 'beginning_of_period',
      billing_trigger_day: -6,
      billing_cycles: 1,
      status: 'active',
      installments: 12,
      invoice_split: false,
      interval_name: 'Anual',
      created_at: '2019-03-25T11:42:55.000-03:00',
      updated_at: '2019-03-25T12:22:34.000-03:00',
      plan_items: [
        {
          id: 113553,
          product: {
            id: 425399,
            name: 'Teste',
            code: null,
            unit: 'hectares',
            status: 'inactive',
          },
          pricing_schema: {
            id: 8136483,
            short_format: 'Variável',
            price: '15.0',
            minimum_price: '750.0',
          },
          created_at: '2019-03-25T11:48:15.000-03:00',
          updated_at: '2019-03-25T12:22:34.000-03:00',
        },
      ],
    },
    {
      id: 413853,
      name: 'DigiFarmz Light - Mensal',
      interval: 'months',
      interval_count: 1,
      billing_trigger_type: 'beginning_of_period',
      billing_trigger_day: 0,
      billing_cycles: 12,
      status: 'active',
      installments: 1,
      invoice_split: false,
      interval_name: 'Mensal',
      created_at: '2024-01-19T09:12:39.000-03:00',
      updated_at: '2024-01-19T09:32:14.000-03:00',
      plan_items: [
        {
          id: 495897,
          product: {
            id: 1430481,
            name: 'DigiFarmz Light - Mensal',
            code: null,
            unit: 'Licença',
            status: 'active',
          },
          pricing_schema: {
            id: 43811047,
            short_format: 'R$ 59,00',
            price: '59.0',
            minimum_price: null,
          },
          created_at: '2024-01-19T09:15:09.000-03:00',
          updated_at: '2024-01-19T09:15:09.000-03:00',
        },
      ],
    },
    {
      id: 413855,
      name: 'DigiFarmz Light - Anual',
      interval: 'months',
      interval_count: 12,
      billing_trigger_type: 'beginning_of_period',
      billing_trigger_day: 0,
      billing_cycles: null,
      status: 'active',
      installments: 1,
      invoice_split: false,
      interval_name: 'Anual',
      created_at: '2024-01-19T09:28:45.000-03:00',
      updated_at: '2024-01-19T09:32:02.000-03:00',
      plan_items: [
        {
          id: 495901,
          product: {
            id: 1425983,
            name: 'DigiFarmz Light - Anual',
            code: null,
            unit: 'Licença',
            status: 'active',
          },
          pricing_schema: {
            id: 43645671,
            short_format: 'R$ 490,00',
            price: '490.0',
            minimum_price: null,
          },
          created_at: '2024-01-19T09:28:45.000-03:00',
          updated_at: '2024-01-19T09:28:45.000-03:00',
        },
      ],
    },
  ],
}

export { paymentMethods, paymentPlans }
