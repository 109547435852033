import { APIFailureWrapper } from '@/utils/mock'
import {
  paymentMethods,
  paymentPlans,
} from '@/modules/payment/mock/paymentSeeds.js'

const paymentRoutes = [
  {
    method: 'get',
    url: '/payments/vindi/payments-methods',
    result: () =>
      APIFailureWrapper({
        content: paymentMethods,
        errorMessage: 'Erro na listagem de métodos de pagamento da Vindi',
      }),
  },
  {
    method: 'get',
    url: '/payments/vindi/plan',
    result: () =>
      APIFailureWrapper({
        content: paymentPlans,
        errorMessage: 'Erro na listagem de planos da Vindi',
      }),
  },
  {
    method: 'post',
    url: '/payments/vindi/customer',
    result: () => {
      const data = {
        id: 1,
        name: 'Teste Digifarmz',
        email: 'dev@digifarmz.com',
        registry_code: '',
        code: '',
        notes: '',
        status: 'active',
        created_at: '2024-07-09T17:14:30.000-03:00',
        updated_at: '2024-07-09T17:14:30.000-03:00',
        address: {
          street: 'Avenida digifarmz',
          number: '10',
          additional_details: '',
          zipcode: '95840000',
          neighborhood: 'centro',
          city: 'Porto Alegre',
          state: 'RS',
          country: 'BR',
        },
        phones: [],
      }
      return APIFailureWrapper({
        content: data,
        failureRate: 10,
      })
    },
  },
  {
    method: 'post',
    url: '/payments/vindi/subscription',
    result: () => {
      return APIFailureWrapper({
        content: null,
        errorMessage: 'Erro na assinatura da Vindi',
        failureRate: 10,
      })
    },
  },
  {
    method: 'post',
    url: '/payments/vindi/bill',
    result: () => {
      return APIFailureWrapper({
        content: null,
        errorMessage: 'Erro na fatura da Vindi',
        failureRate: 10,
      })
    },
  },
  {
    method: 'post',
    url: '/payments/vindi/payment-profiles',
    result: () => {
      return APIFailureWrapper({
        content: null,
        errorMessage: 'Erro na criação do perfil de pagamento da Vindi',
        failureRate: 10,
      })
    },
  },
  {
    method: 'put',
    url: '/payments/vindi/updated-vindi-id',
    result: () => {
      return APIFailureWrapper({
        content: 'Vindi ID atualizado com sucesso',
        errorMessage: 'Erro na atualização do Vindi ID',
        failureRate: 10,
      })
    },
  },
]

export { paymentRoutes }
